import React, { useEffect, useState } from 'react';
import '../styles/index.scss';

function Index() {
  // const [date, setDate] = useState(null);
  // useEffect(() => {
  //   async function getDate() {
  //     const res = await fetch('/api/date');
  //     const newDate = await res.text();
  //     setDate(newDate);
  //   }
  //   getDate();
  // }, []);

  return (
    <main>
      <h1>Szia!</h1>
      <p>Gondolom láttál Diákanpokon és meg akartad tudni ki vagyok</p>
      <h2>Kövess be <a href='https://www.instagram.com/kundiofficial'>instán</a>!
      </h2>
    </main>
  );
}

export default Index;
